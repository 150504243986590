import React from 'react'
import { theme } from '../../theme'

export const Caret: React.FC<{ color?: string; size?: number; className?: string }> = ({
  color = theme.colors.white,
  size = 24,
  className,
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={(28 * size) / 50}
      viewBox="0 0 50 28"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50 3.11111C50 3.9537 49.6908 4.68287 49.0723 5.29861L27.1973 27.0764C26.5788 27.6921 25.8464 28 25 28C24.1536 28 23.4212 27.6921 22.8027 27.0764L0.927734 5.29861C0.309245 4.68287 0 3.9537 0 3.11111C0 2.26852 0.309245 1.53935 0.927734 0.923611C1.54622 0.30787 2.27865 0 3.125 0H46.875C47.7214 0 48.4538 0.30787 49.0723 0.923611C49.6908 1.53935 50 2.26852 50 3.11111Z"
        fill={color}
      />
    </svg>
  )
}
