import React from 'react'
import { View } from '../components/view'
import { createUseStyles } from 'react-jss'
import { Theme, theme } from '../theme'
import { team } from '../utils/team'
import { ImageCard } from '../components/cards/imageCard'

const useStyles = createUseStyles((theme: Theme) => ({
  teamMembers: {
    display: 'grid',
    gridTemplateColumns: '33% 33% 33%',
    gridAutoRows: '1fr',
    gap: '1em',
    width: '90%',
    margin: 'auto',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '50% 50%',
    },
    [theme.breakpoints.down('sm')]: {
      ...theme.common.flexBox,
      flexDirection: 'column',
    },
  },
}))

export const TeamScreen: React.FC = () => {
  const classes = useStyles()

  return (
    <View>
      <div className={classes.teamMembers}>
        {team.map(member => (
          <ImageCard
            key={member.name}
            image={member.image}
            title={member.name}
            subTitle={member.role}
            titleStyle={{ ...theme.fontSize.sortOfLarge }}
          />
        ))}
      </div>
    </View>
  )
}
