import React from 'react'
import { View } from '../components/view'
import { FAQS } from '../utils/faqs'
import { ContentCard } from '../components/cards/contentCard'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(() => ({
  faqList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '2em',
  },
}))

export const FaqScreen: React.FC = () => {
  const classes = useStyles()

  return (
    <View>
      <div className={classes.faqList}>
        {FAQS.map((faq, index) => (
          <ContentCard key={index} title={faq.question} body={faq.answer} />
        ))}
      </div>
    </View>
  )
}
