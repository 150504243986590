import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../../theme'
import { classNames } from '../../utils/classNames'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    height: 'auto',
    width: '90%',
    margin: 'auto',
    backgroundColor: theme.colors.white,
    padding: '35px 20px',
    borderRadius: 10,
  },
  title: {
    ...theme.typography.base,
    color: theme.colors.darkGreen,
    ...theme.fontSize.larger,
    paddingBottom: 20,
    textAlign: 'center',
  },
  body: {
    ...theme.typography.base,
    color: theme.colors.darkGrey,
    ...theme.fontSize.medium,
    textAlign: 'center',
    lineHeight: 1.5,
  },
}))

export const ContentCard: React.FC<{ className?: string; title: string | JSX.Element; body: string | JSX.Element }> = ({
  className,
  title,
  body,
}) => {
  const classes = useStyles()

  return (
    <div className={classNames(classes.container, className)}>
      {typeof title === 'string' ? (
        <h1 className={classes.title}>{title}</h1>
      ) : (
        <div className={classes.title}>{title}</div>
      )}
      {typeof body === 'string' ? <p className={classes.body}>{body}</p> : <div className={classes.body}>{body}</div>}
    </div>
  )
}
