import React from 'react'
import { View } from '../components/view'
import { ContentCard } from '../components/cards/contentCard'
import { CAREERS_BODY, CAREERS_HEADER } from '../utils/strings'

export const CareersScreen: React.FC = () => {
  return (
    <View>
      <ContentCard title={CAREERS_HEADER} body={CAREERS_BODY} />
    </View>
  )
}
