import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { theme, Theme } from '../theme'
import background from '../assets/Images/Background.png'
import { Link } from 'react-router-dom'
import LOGO from '../assets/Images/words-logo2.png'
import { HamburgerMenu } from './buttons/hamburgerMenu'
import { Caret } from '../assets/Icons/caret'
import { classNames } from '../utils/classNames'

const useStyles = createUseStyles((theme: Theme) => ({
  //COMMON
  headerContainer: {
    position: 'fixed',
    '&:after': {
      content: '""',
      backgroundImage: `url(${background})`,
      borderRadius: '0 0 15px 15px',
      top: 0,
      minWidth: '100%',
      height: 100,
      position: 'fixed',
      zIndex: -1,
    },
  },
  headerContainerExtended: {
    position: 'fixed',
    backgroundColor: theme.colors.darkGrey,
    '&:after': {
      content: '""',
      backgroundColor: theme.colors.opaqueBlack,
      borderRadius: '0 0 15px 15px',
      top: 0,
      minWidth: '100%',
      height: 248,
      position: 'fixed',
      zIndex: -1,
    },
  },
  caret: {
    marginBottom: 2,
  },
  logo: {
    ...theme.elements.link,
    display: 'flex',
    alignItems: 'center',
  },
  fourLogo: {
    height: 64,
    width: 'auto',
    marginBottom: 10,
    [theme.breakpoints.down('sm')]: {
      height: 50,
    },
  },
  logoLetters: {
    ...theme.typography.fancy,
    ...theme.fontSize.largest,
    fontStyle: 'italic',
    marginLeft: -12,
    [theme.breakpoints.down('sm')]: {
      marginLeft: -10,
    },
  },
  resources: {
    position: 'fixed',
    backgroundColor: theme.colors.opaqueWhite,
    height: 'auto',
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5em',
    padding: 20,
    borderRadius: 10,
  },
  resourceLink: {
    color: `${theme.colors.darkGreen} !important`,
    '&:hover': {
      textShadow: `0 0 10px ${theme.colors.gold} !important`,
      transition: 'text-shadow 0.2s !important',
    },
  },
  navLink: {
    ...theme.elements.link,
    ...theme.typography.base,
    ...theme.fontSize.large,
    '&:hover, &:active': {
      color: theme.colors.gold,
      transition: 'color 0.2s',
    },
  },
  //DESKTOP
  header: {
    position: 'fixed',
    zIndex: 100,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    backgroundColor: theme.colors.opaqueBlack,
    minWidth: '100%',
    height: 100,
    marginBottom: 50,
    padding: '0 20px',
    borderRadius: '0 0 15px 15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  links: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: '2em',
    justifyContent: 'flex-end',
  },
  //MOBILE
  headerMobile: {
    position: 'fixed',
    zIndex: 100,
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    minWidth: '100%',
    height: 'auto',
    backgroundColor: theme.colors.opaqueBlack,
    padding: '0 20px',
    borderRadius: '0 0 15px 15px',
    marginBottom: 30,
  },
  mobileTop: {
    display: 'flex',
    justifyContent: 'space-between',
    height: 100,
    alignItems: 'center',
  },
  mobileLinks: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    gap: '1em',
    paddingBottom: 20,
  },
}))

const LogoLink = () => {
  const classes = useStyles()

  return (
    <Link to={'/'} className={classes.logo}>
      <img src={LOGO} alt={'Logo'} className={classes.fourLogo} />
    </Link>
  )
}

const Links: React.FC<{ containerClassName: string }> = ({ containerClassName }) => {
  const classes = useStyles()
  const [isResourceHovered, setIsResourceHovered] = useState(false)
  const [isResourceOpen, setIsResourceOpen] = useState(false)

  return (
    <div className={containerClassName}>
      <Link to={'/about'} className={classes.navLink}>
        About
      </Link>
      <Link to={'/contact'} className={classes.navLink}>
        Contact
      </Link>
      <div
        role={'button'}
        tabIndex={0}
        className={classes.navLink}
        onMouseEnter={() => setIsResourceHovered(true)}
        onMouseLeave={() => setIsResourceHovered(false)}
        onClick={() => setIsResourceOpen(!isResourceOpen)}
        onKeyDown={() => setIsResourceOpen(!isResourceOpen)}
      >
        Resources&nbsp;
        <Caret className={classes.caret} size={18} color={isResourceHovered ? theme.colors.gold : theme.colors.white} />
        {isResourceOpen && (
          <div className={classes.resources}>
            <Link to={'/locations'} className={classNames(classes.navLink, classes.resourceLink)}>
              Locations
            </Link>
            <Link to={'/team'} className={classNames(classes.navLink, classes.resourceLink)}>
              Team
            </Link>
            <Link to={'/careers'} className={classNames(classes.navLink, classes.resourceLink)}>
              Careers
            </Link>
            <Link to={'/faq'} className={classNames(classes.navLink, classes.resourceLink)}>
              FAQ&apos;s
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export const Header: React.FC<{ mobileMenuOpen: boolean; setMobileMenuOpen: (currentState: boolean) => unknown }> = ({
  mobileMenuOpen,
  setMobileMenuOpen,
}) => {
  const classes = useStyles()
  const onHamburgerMenuClick = () => {
    setMobileMenuOpen(!mobileMenuOpen)
  }

  return (
    <span className={mobileMenuOpen ? classes.headerContainerExtended : classes.headerContainer}>
      <nav className={classes.header}>
        <LogoLink />
        <Links containerClassName={classes.links} />
      </nav>
      <nav className={classes.headerMobile}>
        <div className={classes.mobileTop}>
          <LogoLink />
          <HamburgerMenu onClick={onHamburgerMenuClick} isOpen={mobileMenuOpen} size={38} />
        </div>
        {mobileMenuOpen && <Links containerClassName={classes.mobileLinks} />}
      </nav>
    </span>
  )
}
