import { createUseStyles } from 'react-jss'
import background from './assets/Images/Background.png'
import base from './assets/Fonts/Metropolis.otf'
import fancy from './assets/Fonts/Aero.ttf'

const colors = {
  white: '#f4f4f4',
  darkGreen: '#1e6626',
  lightGreen: '#b5d2b7',
  gold: '#dabf4c',
  darkGrey: '#4f4f4f',
  opaqueBlack: 'rgba(0, 0, 0, 0.5)',
  opaqueWhite: 'rgba(244, 244, 244, 0.8)',
}

const maxWidthMedia = (size: number) => `@media screen and (max-width: ${size}px)`
const minWidthMedia = (size: number) => `@media screen and (min-width: ${size}px)`
type Breakpoint = 'sm' | 'md'

const breakpoints = {
  smWidth: 480,
  mdWidth: 768,
  down: (size: Breakpoint): string => {
    switch (size) {
      case 'sm':
        return maxWidthMedia(breakpoints.smWidth - 1)
      case 'md':
        return maxWidthMedia(breakpoints.mdWidth - 1)
    }
  },
  up: (size: Breakpoint): string => {
    switch (size) {
      case 'sm':
        return minWidthMedia(breakpoints.smWidth)
      case 'md':
        return minWidthMedia(breakpoints.mdWidth)
    }
  },
}

const baseTypography = {
  fontFamily: 'Base, sans-serif',
  color: colors.white,
  lineHeight: 4 / 3,
}

const fancyTypography = {
  fontFamily: 'Fancy, sans-serif',
  color: colors.darkGreen,
  lineHeight: 4 / 3,
}

const typography = {
  base: baseTypography,
  fancy: fancyTypography,
}

const fontSize = {
  smallest: {
    fontSize: 12,
  },
  small: {
    fontSize: 14,
  },
  medium: {
    fontSize: 20,
  },
  large: {
    fontSize: 24,
  },
  sortOfLarge: {
    fontSize: 32,
  },
  larger: {
    fontSize: 40,
    [breakpoints.down('md')]: {
      fontSize: 32,
    },
  },
  largest: {
    fontSize: 50,
    [breakpoints.down('md')]: {
      fontSize: 40,
    },
    [breakpoints.down('sm')]: {
      fontSize: 32,
    },
  },
  extraLarge: {
    fontSize: 68,
    [breakpoints.down('md')]: {
      fontSize: 50,
    },
  },
}

const dimensions = {
  headerContainerHeight: 150,
  footerContainerHeight: 150,
}

const elements = {
  button: {
    borderStyle: 'none',
    padding: 0,
    borderRadius: 10,
    backgroundColor: 'transparent',
    '&:active': {
      opacity: 0.2,
      tapHighlightColor: 'transparent',
    },
    '&:hover': {
      cursor: 'pointer',
    },
    '&:focus': {
      outline: 'none',
    },
  },
  link: {
    borderStyle: 'none',
    '&:focus': {
      outline: 'none',
    },
    '&:hover': {
      cursor: 'pointer',
    },
    '&:active': {
      opacity: 0.2,
      tapHighlightColor: 'transparent',
    },
    textDecoration: 'none',
  },
}

const common = {
  flexBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}

export const useGlobalStyles = createUseStyles({
  '@global': {
    'html, body, #root': {
      margin: 0,
      height: '100%',
      ...typography.base,
    },
    body: {
      backgroundColor: colors.darkGrey,
      alignItems: 'center',
      '&:after': {
        content: '""',
        backgroundImage: `url(${background})`,
        opacity: 0.3,
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'fixed',
        zIndex: -1,
      },
    },
    '*, *::before, *::after': {
      boxSizing: 'border-box',
    },
    'ul, ol': {
      padding: 0,
      margin: 0,
      listStyle: 'none',
    },
    'h1,h2,h3,h4,h5,p,li': {
      margin: 0,
    },
  },
  '@font-face': [
    {
      fontFamily: 'Base',
      src: `local('Base'), url(${base}) format('opentype')`,
    },
    {
      fontFamily: 'Fancy',
      src: `local('Fancy'), url(${fancy}) format('truetype')`,
    },
  ],
})

export const theme = {
  colors,
  typography,
  fontSize,
  elements,
  breakpoints,
  common,
  dimensions,
}

export type Theme = typeof theme
