export const SLOGAN1 = `The Future of Contactless Payment.`
export const SLOGAN2 = `NEVER LEAVE YOUR CARD AT THE BAR AGAIN.`
export const ABOUT_CARD_HEADER = `About 4wardPay`
export const ABOUT_CARD_BODY = ` is a revolutionary contactless payment system for consumers and bar/nightclubs proprietors. The system aims to remove inefficiencies in the payment process that will (1) greatly improve the customer experience and (2) increases sales turnover. It's a fast, safe and convenient payment method for both the patron and the bartender that integrates directly into existing POS systems. 4wardPay created an entertaining and sophisticated atmosphere for opening and closing a tab with fast and user friendly service.`
export const FOOTER_MESSAGE = `${new Date().getFullYear()} 4wardPay LLC`
export const CONTACT_HEADER = `We'd love to hear from you!`
export const CONTACT_BODY = `For help or feedback, please contact support@4wardPay.app and we will get back to you as soon as possible.`
export const LOCATIONS_HEADER = `4wardPay is coming to you!`
export const LOCATIONS_BODY = `4wardPay will soon be rolling out to a venue near you, starting in the North East!`
export const CAREERS_HEADER = `We want you!`
export const CAREERS_BODY = `Job listings soon to be posted!`
export const NOT_FOUND_HEADER = `Oops!`
export const NOT_FOUND_BODY = `The page you're looking for does not exist!`
