import React, { useState } from 'react'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(() => ({
  pdf: {
    '& canvas': {
      width: '100% !important',
      height: 'auto !important',
    },
  },
}))

export const PDFViewer: React.FC<{ pdf: File }> = ({ pdf }) => {
  const classes = useStyles()

  const [numPages, setNumPages] = useState<number>()
  console.log(pdf)

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages)
  }

  return (
    <Document
      className={classes.pdf}
      file={pdf}
      options={{ workerSrc: '/pdf.worker.js' }}
      onLoadSuccess={onDocumentLoadSuccess}
    >
      {Array.from(new Array(numPages), (el, index) => (
        <Page key={`page_${index + 1}`} pageNumber={index + 1} />
      ))}
    </Document>
  )
}
