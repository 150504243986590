import React from 'react'
import { Hamburger } from '../../assets/Icons/hamburger'
import { createUseStyles } from 'react-jss'
import { theme, Theme } from '../../theme'
import { classNames } from '../../utils/classNames'
import { Close } from '../../assets/Icons/close'

const useStyles = createUseStyles((theme: Theme) => ({
  button: {
    ...theme.elements.button,
    border: `1px solid ${theme.colors.opaqueWhite} !important`,
    borderRadius: 5,
    padding: 5,
    lineHeight: '0 !important',
  },
}))

export const HamburgerMenu: React.FC<{
  className?: string
  color?: string
  onClick: () => void
  size?: number
  isOpen: boolean
}> = ({ className, onClick, size = 36, isOpen }) => {
  const classes = useStyles()

  return (
    <button onClick={onClick} style={{}} className={classNames(classes.button, className)}>
      {isOpen ? (
        <Close color={theme.colors.opaqueWhite} size={(size * 2) / 3} />
      ) : (
        <Hamburger color={theme.colors.opaqueWhite} size={(size * 2) / 3} />
      )}
    </button>
  )
}
