import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { classNames } from '../utils/classNames'
import { Header } from './header'
import { Footer } from './footer'

const useStyles = createUseStyles((theme: Theme) => ({
  viewContainer: {
    minHeight: 'calc(100vh - 150px)',
    position: 'relative',
  },
  contentContainer: {
    padding: '150px 0',
    overflow: 'hidden',
    ...theme.common.flexBox,
    minHeight: '100vh',
  },
}))

export const View: React.FC<{ contentClassName?: string }> = ({ children, contentClassName }) => {
  const classes = useStyles()
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <div className={classes.viewContainer}>
      <Header mobileMenuOpen={mobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />
      <div className={classNames(classes.contentContainer, contentClassName)}>{children}</div>
      <Footer />
    </div>
  )
}
