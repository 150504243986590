import React from 'react'
import { View } from '../components/view'
import { createUseStyles } from 'react-jss'
import { theme, Theme } from '../theme'
import BG2 from '../assets/Images/bg2.png'
import BGGRAY from '../assets/Images/bg-gray-gradient.png'
import BGGREEN from '../assets/Images/bg-green-gradient.png'
import SCANSS from '../assets/Images/scanSS.png'
import ORDERSS from '../assets/Images/orderSS.png'
import PAYSS from '../assets/Images/paySS.png'
import { classNames } from '../utils/classNames'
import { SLOGAN1, SLOGAN2 } from '../utils/strings'
import { ImageCard } from '../components/cards/imageCard'

const useStyles = createUseStyles((theme: Theme) => ({
  content: {
    flexDirection: 'column',
  },
  sloganBox: {
    backgroundSize: 'auto 100%',
    width: '100%',
    height: '80vh',
    ...theme.common.flexBox,
    ...theme.typography.base,
    ...theme.fontSize.extraLarge,
    textShadow: `0px 0px 50px ${theme.colors.gold}`,
    padding: 20,
    textAlign: 'center',
  },
  futureContactlessPayments: {
    backgroundImage: `url(${BGGRAY})`,
    marginTop: -150,
  },
  slogan1: {
    marginTop: 50,
  },
  ospGraphic: {
    padding: '0 10%',
    backgroundImage: `url(${BG2})`,
    backgroundSize: 'auto 100%',
    width: '100%',
    height: 'auto',
    ...theme.common.flexBox,
    [theme.breakpoints.down('md')]: {
      padding: '0 0%',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: '20px 0',
    },
  },
  screenShotCard: {
    width: 'auto',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '65%',
      padding: 0,
    },
  },
  neverForgetCard: {
    backgroundImage: `url(${BGGREEN})`,
    marginBottom: -50,
  },
}))

export const HomeScreen: React.FC = () => {
  const classes = useStyles()

  return (
    <View contentClassName={classes.content}>
      <div className={classNames(classes.futureContactlessPayments, classes.sloganBox)}>
        <span className={classes.slogan1}>{SLOGAN1}</span>
      </div>
      <div className={classes.ospGraphic}>
        <ImageCard
          className={classes.screenShotCard}
          image={SCANSS}
          title={'SCAN'}
          backgroundColor={'transparent'}
          titleStyle={{
            ...theme.typography.fancy,
            textShadow: `0 0 10px ${theme.colors.gold}`,
          }}
        />
        <ImageCard
          className={classes.screenShotCard}
          image={ORDERSS}
          title={'ORDER'}
          backgroundColor={'transparent'}
          titleStyle={{
            ...theme.typography.fancy,
            textShadow: `0 0 10px ${theme.colors.gold}`,
          }}
        />
        <ImageCard
          className={classes.screenShotCard}
          image={PAYSS}
          title={'PAY'}
          backgroundColor={'transparent'}
          titleStyle={{
            ...theme.typography.fancy,
            textShadow: `0 0 10px ${theme.colors.gold}`,
          }}
        />
      </div>
      <div className={classNames(classes.neverForgetCard, classes.sloganBox)}>
        <span>{SLOGAN2}</span>
      </div>
      {/*  <ImageCard*/}
      {/*    className={classes.screenShotCard}*/}
      {/*    image={ORDER_SCREEN_SHOT}*/}
      {/*    title={'ORDER'}*/}
      {/*    backgroundColor={'transparent'}*/}
      {/*    titleStyle={{*/}
      {/*      ...theme.typography.fancy,*/}
      {/*      ...theme.fontSize.larger,*/}
      {/*      textShadow: `0 0 10px ${theme.colors.gold}`,*/}
      {/*    }}*/}
      {/*  />*/}
      {/*  <ImageCard*/}
      {/*    className={classes.screenShotCard}*/}
      {/*    image={SCAN_SCREEN_SHOT}*/}
      {/*    title={'SCAN'}*/}
      {/*    backgroundColor={'transparent'}*/}
      {/*    titleStyle={{*/}
      {/*      ...theme.typography.fancy,*/}
      {/*      ...theme.fontSize.larger,*/}
      {/*      textShadow: `0 0 10px ${theme.colors.gold}`,*/}
      {/*    }}*/}
      {/*  />*/}
      {/*  <ImageCard*/}
      {/*    className={classes.screenShotCard}*/}
      {/*    image={PAY_SCREEN_SHOT}*/}
      {/*    title={'PAY'}*/}
      {/*    backgroundColor={'transparent'}*/}
      {/*    titleStyle={{*/}
      {/*      ...theme.typography.fancy,*/}
      {/*      ...theme.fontSize.larger,*/}
      {/*      textShadow: `0 0 10px ${theme.colors.gold}`,*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</div>*/}
    </View>
  )
}
