import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { HomeScreen } from './screens/home'
import { AboutScreen } from './screens/about'
import { ContactScreen } from './screens/contact'
import { LocationsScreen } from './screens/locations'
import { CareersScreen } from './screens/careers'
import { TeamScreen } from './screens/team'
import { FaqScreen } from './screens/faq'
import { TermsAndConditionsScreen } from './screens/termsAndConditions'
import { PrivacyPolicyScreen } from './screens/privacyPolicy'
import { NotFoundScreen } from './screens/not-found'

const AppRouter: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={HomeScreen} />
        <Route exact path="/about" component={AboutScreen} />
        <Route exact path="/contact" component={ContactScreen} />
        <Route exact path="/locations" component={LocationsScreen} />
        <Route exact path="/careers" component={CareersScreen} />
        <Route exact path="/team" component={TeamScreen} />
        <Route exact path="/faq" component={FaqScreen} />
        <Route exact path="/terms-and-conditions" component={TermsAndConditionsScreen} />
        <Route exact path="/privacy-policy" component={PrivacyPolicyScreen} />
        <Route component={NotFoundScreen} />
      </Switch>
    </BrowserRouter>
  )
}

export default AppRouter
