import React from 'react'
import { View } from '../components/view'
import { ContentCard } from '../components/cards/contentCard'
import { NOT_FOUND_BODY, NOT_FOUND_HEADER } from '../utils/strings'

export const NotFoundScreen: React.FC = () => {
  return (
    <View>
      <ContentCard title={NOT_FOUND_HEADER} body={NOT_FOUND_BODY} />
    </View>
  )
}
