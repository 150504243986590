import React from 'react'
import { View } from '../components/view'
import { ContentCard } from '../components/cards/contentCard'
import { CONTACT_BODY, CONTACT_HEADER } from '../utils/strings'

export const ContactScreen: React.FC = () => {
  return (
    <View>
      <ContentCard title={CONTACT_HEADER} body={CONTACT_BODY} />
    </View>
  )
}
