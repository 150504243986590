import React from 'react'
import { View } from '../components/view'
import { ContentCard } from '../components/cards/contentCard'
import { ABOUT_CARD_BODY, ABOUT_CARD_HEADER } from '../utils/strings'

export const AboutScreen: React.FC = () => {
  return (
    <View>
      <ContentCard
        title={ABOUT_CARD_HEADER}
        body={
          <div>
            <b>4wardPay</b>
            {ABOUT_CARD_BODY}
          </div>
        }
      />
    </View>
  )
}
