import PIERRE_AVATAR from '../assets/Images/pierreAvatar.png'
import LEE_AVATAR from '../assets/Images/LeeAvatar.png'
import JACK_AVATAR from '../assets/Images/JackAvatar.png'
import CHIARA_AVATAR from '../assets/Images/ChiaraAvatar.png'

type TeamMember = {
  name: string
  role: string
  image: string
}

export const team: TeamMember[] = [
  {
    name: 'Pierre Martin',
    role: 'CEO, Founder',
    image: PIERRE_AVATAR,
  },
  {
    name: 'Lee Feinstein',
    role: 'CTO, Co-Founder',
    image: LEE_AVATAR,
  },
  {
    name: 'Jack Feinstein',
    role: 'Lead Frontend Developer',
    image: JACK_AVATAR,
  },
  {
    name: 'Kunle Obafemi',
    role: 'Regional Account Manager',
    image: LEE_AVATAR,
  },
  {
    name: 'John Tomlin, Esq',
    role: 'Legal Counsel',
    image: PIERRE_AVATAR,
  },
]
