import React from 'react'
import { View } from '../components/view'
import { ContentCard } from '../components/cards/contentCard'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { PDFViewer } from '../components/pdf/pdf-viewer'
import TERMS_AND_CONDITIONS from '../assets/files/Terms&Conditions.docx.pdf'

const useStyles = createUseStyles((theme: Theme) => ({
  document: {
    height: `calc(100vh - ${theme.dimensions.headerContainerHeight}px - ${theme.dimensions.footerContainerHeight}px)`,
    overflow: 'scroll',
  },
}))

export const TermsAndConditionsScreen: React.FC = () => {
  const classes = useStyles()

  return (
    <View>
      <ContentCard
        className={classes.document}
        title={'Terms & Conditions'}
        body={<PDFViewer pdf={TERMS_AND_CONDITIONS} />}
      />
    </View>
  )
}
