import React from 'react'
import { createUseStyles } from 'react-jss'
import { Theme } from '../theme'
import { FOOTER_MESSAGE } from '../utils/strings'
import { Link } from 'react-router-dom'
import { classNames } from '../utils/classNames'

const useStyles = createUseStyles((theme: Theme) => ({
  footerContainer: {
    height: 100,
    width: '100%',
    borderRadius: '15px 15px 0 0',
    marginTop: 50,
    backgroundColor: theme.colors.opaqueBlack,
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 15,
  },
  footerContent: {
    ...theme.typography.base,
    color: theme.colors.white,
  },
  footerLink: {
    ...theme.elements.link,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}))

export const Footer: React.FC = () => {
  const classes = useStyles()

  return (
    <div className={classes.footerContainer}>
      <p className={classes.footerContent}>&#xa9; {FOOTER_MESSAGE}</p>
      <p>
        <Link to={'/terms-and-conditions'} className={classNames(classes.footerContent, classes.footerLink)}>
          Terms & Conditions
        </Link>{' '}
        |{' '}
        <Link to={'/privacy-policy'} className={classNames(classes.footerContent, classes.footerLink)}>
          Privacy Policy
        </Link>
      </p>
    </div>
  )
}
