import React from 'react'
import { createUseStyles } from 'react-jss'
import { theme, Theme } from '../../theme'
import { classNames } from '../../utils/classNames'

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    height: 'auto',
    margin: '0 auto',
    padding: '35px 20px',
    borderRadius: 10,
  },
  title: {
    ...theme.typography.base,
    color: theme.colors.darkGreen,
    ...theme.fontSize.larger,
    textAlign: 'center',
  },
  subTitle: {
    ...theme.typography.base,
    color: theme.colors.darkGrey,
    ...theme.fontSize.medium,
    textAlign: 'center',
    paddingBottom: 20,
  },
  image: {
    width: '100%',
  },
}))

export const ImageCard: React.FC<{
  title?: string
  subTitle?: string
  image: string
  backgroundColor?: string
  className?: string
  titleStyle?: Record<string, unknown>
}> = ({ title, subTitle, image, backgroundColor = theme.colors.white, className, titleStyle }) => {
  const classes = useStyles()

  return (
    <div className={classNames(classes.container, className)} style={{ backgroundColor: backgroundColor }}>
      <h1 className={classes.title} style={titleStyle}>
        {title}
      </h1>
      <h2 className={classes.subTitle}>{subTitle}</h2>
      <img src={image} alt={title} className={classes.image} />
    </div>
  )
}
