import React from 'react'

export const FAQS: { question: string; answer: string | JSX.Element }[] = [
  {
    question: 'Can 4wardPay integrate with my POS?',
    answer: (
      <>
        <p>4wardPay currently has integration partnership with the following POS systems:</p>
        <ul>
          <li id={'pos'}>Clover</li>
          <li id={'pos'}>Micros Symphony SE</li>
          <li id={'pos'}>Micros Symphony</li>
        </ul>
        <p>POS soon to be onboarded:</p>
        <ul>
          <li id={'pos'}>SpotOn</li>
          <li id={'pos'}>NCR/Aloha</li>
          <li id={'pos'}>NCR/Silver</li>
          <li id={'pos'}>Toast</li>
          <li id={'pos'}>Upserve</li>
          <li id={'pos'}>POSItouch</li>
          <li id={'pos'}>Lightspeed</li>
          <li id={'pos'}>2TouchPOS</li>
          <li id={'pos'}>Leebro POS</li>
          <li id={'pos'}>POS.com</li>
        </ul>
      </>
    ),
  },
  {
    question: `What happens if a patrons leaves the venue without paying?`,
    answer: `4wardPay will offer a 100% walkout protection as a credit card is required to open a tab with 4wardPay App. All un-closed tabs will be closed at the end of the night with a 20% gratuity.`,
  },
  {
    question: `Do patrons tip better?`,
    answer: `Patrons will tend to leave better tips with 4wardPay as service will be more convenient and efficient.`,
  },
  {
    question: `Do patrons have to download the app?`,
    answer: `In order to conveniently open and close your tab with the use of your smartphone you will have to download the 4wardpay App.`,
  },
  {
    question: `Will the user have to pay for downloading the app?`,
    answer: `4wardPay app is free to download, the user will incur a small convenience fee per transaction.`,
  },
  {
    question: `Is the user's information safe?`,
    answer: `All payment card data is encrypted and transmitted securely. We adhere to all PCI compliance standards and go above and beyond those standards to make sure all your data is safe.`,
  },
  {
    question: `How fast can 4wardPay integrate with my system?`,
    answer: `This depends one what POS you use. If we are already integrated with your current POS system, setup can be completed and you can be up and running in as little as an hour!`,
  },
  // {
  //   question: `What does onboarding Process involve?`,
  //   answer: `You will need to create a Stripe business account to receive payments. This is all online and quick to do but it must be done by an executive or equivalent within your business.`,
  // },
  {
    question: `How will I get paid`,
    answer: `This depends on the merchant account you choose. For instance, if you use Stripe we can send the money directly to your stripe account.`,
  },
  {
    question: `How are tips managed through app?`,
    answer: `Within the app a customer can select a percentage or amount to tip at checkout.`,
  },
  {
    question: `Who can I contact if I have an issue?`,
    answer: `Email us at support@4wardpay.app`,
  },
  {
    question: `What payment methods does 4wardPay support?`,
    answer: `4wardPay accepts all major debit and credit cards with Apple Pay, Google Wallet integration coming soon.`,
  },
]
